import React from "react";
import { useParams, Link } from "react-router-dom";
import "../css/NewsDetail.css";

const newsDetails = {
  1: {
    title: " 日本語表示の終了について",
    date: "2021-07-15",
    content: `
いつも BitStables をご利用いただき誠にありがとうございます。

2021年8月14日をもって日本語・韓国語・中国語表示を終了させて頂きます。
英語表示のみになりますことをお知らせいたします。
また、2021年8月15日より言語の選択から日本語表示が取り外されますのでご注意ください。

Bitstables
    `
  },
  2: {
    title: " Notice of Termination of Withdrawal Service for Delisted Currencies and USDT",
    date: "2020-10-01",
    content: `
Notice of Termination of Withdrawal Service for Delisted Currencies and USDT
● Delisted currencies and USDT
● Withdrawal End Date: December 31, 2020
● After the withdrawal service is closed, withdrawal requests will be handled via email.
● Sending fee: 0.5 ETH
● Please prepare an Ethereum address for currency receipt.
● USDT withdrawals will be terminated.

We apologize for any inconvenience this may cause to our members.
For more information, please contact BS SUPPORT.
      
            
Bitstables
    `
  },
  3: {
    title: " Regarding transaction restrictions for residents of Japan",
    date: "2019-03-03",
    content: `
Bitstables.com will gradually terminate the provision of services to customers residing in Japan.
In addition, Japanese language support will end in stages.
We apologize for any inconvenience this may cause to our customers residing in Japan. Thank you for your understanding and cooperation.

Bitstable
    `
  },
  4: {
    title: "  Notice of withdrawal restrictions",
    date: "2019-03-03",
    content: `
Thank you for using bitStables.


As of March 3, 2019, the minimum withdrawal amount for BTC will be 0.02 BTC and the minimum withdrawal amount for ETH will be 0.2 ETH.
KYC personal authentication will be required for withdrawals, so if you have not registered for KYC, please do so as soon as possible


About Withdrawals 
This site also provides a platform for trading cryptocurrencies.
We do not allow it to be used as a wallet for fraudulent money transfers.
Cryptocurrencies deposited with us cannot be withdrawn without trading.
If you have not made a transaction of $1000 or more in 365 days, you will need to make a transaction of $1000 or more. 
In some cases, we may have to reconfirm the identity of the person who made the deposit, the reason for withdrawing without trading, 
investigate the source of the deposit, investigate the destination, and confirmation the user.
New methods to increase the traceability of crypto assets are being developed one after another, and we ask for your cooperation in taking 
measures to prevent traceability and anonymity to accurately understand the characteristics of each crypto asset.
*If a survey is required, regardless of whether it is conducted by our company or by an expert, the member will be responsible for the survey
 fee of 500 USDT per day and will be billed at a later date.

 
Precautions for Unauthorized Access
When you open an account with us, we will verify your identity (KYC authentication) and your user ID and password will never be used by anyone
 other than you.
If you use (or log in to) an account in someone else's name (including a family member's name), the account will be frozen without notice and the 
assets will be confiscated as a result of unauthorized access, money laundering, or other illegal activities.
The same applies to cases where the account holder has been entrusted by a family member, friend, or acquaintance to handle all transactions 
(i.e., the account holder is making investment decisions on behalf of the account holder, or the account holder is trading in a currency that 
does not belong to the account holder).
As part of our efforts to prevent money laundering, we conduct thorough identity verification, and if we suspect or confirm that a person other 
than the accountholder (including family members) is conducting transactions (including logins), we will freeze the account and confiscate the assets.
If the person who manipulated the account in another person's name (including a family member's name) has an account with Bitstables, the account
 of the person who manipulated the account will also be frozen and the assets will be confiscated.
If there is a high possibility of fraudulent login, if we do not recognize it as fraudulent login, if the user's words and actions are unnatural, 
if the user does not cooperate with our questions, or if the user does not respond to our questions, we will conduct our own investigation or ask 
experts to conduct an investigation without a prior report in order to confirm the truth at our discretion.
We may need to reconfirm your identity, investigate unauthorized login by others (including family members), interview you, investigate your
 shipping address, investigate the source of your payment, or conduct other undisclosed investigations.
If we need to investigate, you will be charged 500 USDT/day regardless of whether it is our investigation or expert investigation.
You will be billed at a later date.



Invalid and Suspended Account

You need more than $1000 transactions per 365 days. If you don’t, your account will be limited.
If you don’t have $1000 transactions for more than 730 days, your account will be invalid and suspended. Also, your assets will be forfeited.
If you have not logged in for more than 650 days, your account will be invalidated and your account will be terminated.In addition,
its assets will be confiscated.
If you have not made a deposit for more than 365 days after registration and have not logged in for more than 365 days, your account will be
invalidated and your account will be terminated.
If you don’t register KYC more than 365 days after you successfully registered, your account will be invalid, and your assets will be forfeited.



 

Thank you for your understanding.

Thank you for your continued support of BitStables




--------------------------------------------------

BitStables

Customer support

--------------------------------------------------
    `
  },
  5: {
    title: "  Notice of Removal of Deposit Restrictions",
    date: "2019-01-31",
    content: `
Notice of Removal of Deposit Restrictions



It is now possible to deposit and trade without KYC authentication.

However, you will need to register for KYC authentication to withdraw funds.




BItStables
    `
  },
  6: {
    title: "  End of Chinese, Korean, Japanese Language Display",
    date: "2021-07-15",
    content: `
    
Thank you for using BitStables.
As of August 14, 2021, the Japanese, Korean, and Chinese language display will be discontinued.
Please be advised that from August 15, 2021, only English will be available.


BItStables
    `
  },
  7: {
    title: "   Re: Invalidity of Email Addresses that are Violating Request for Comments (RFC)",
    date: "2020-01-01",
    content: `
Re: Invalidity of Email Addresses that are Violating Request for Comments (RFC)


All emails sent from “Bitstables” are going through our security system, therefore, we cannot send any important emails of you to email addresses that are violating RFCs (Request for Comments). Also, it is impossible to make new registrations with email addresses falling into such cases.
This is a step taken for making it complied with international standards for e-mail addresses and for  prevention of international crimes and fraudulent accounts that target new registration bonuses, airdrops, price manipulation, and other illegal activities.
The account which is registered with an email address that can be linked to a certain email address shall be in validated, and its related asset will also be forfeited.

 
What does RFC-Violation mean ?
E-mail addresses that do not conform to the specifications of RFC (Request For Comments) are referred to as “RFC-Violation mail address”.

 
Examples of criteria for “RFC-Violation mail address “are as follows 
(1) When the format of the local part of the e-mail address is incorrect
e.g.: “,” is placed before "@", or "."is placed at the beginning or the end, within the scripts of email address.
 

(2) When the domain part of the e-mail address is formatted incorrectly
　 e.g.: "."is placed after "@", or "." is placed at the beginning or the end within the scripts of email address..
 

(3)Any other case where the format of the e-mail address is incorrect,
e.g.: contains "+", etc. within the scripts of email address)
 
Bitstables
    `
  },
  8: {
    title: "  Bitstables Service Termination Notice",
    date: "2024-12-31",
    content: `
    
Dear Valued Customers,

We would like to express our sincere gratitude for your continued support of Bitstables.

As of December 31, 2024, we have ceased our services.

We deeply appreciate your loyalty over the years.

For inquiries regarding withdrawals, please contact the BS Support Center at the following link:

<a href="https://lin.ee/DrpJOhW" target="_blank" rel="noreferrer">https://lin.ee/DrpJOhW</a>

Please note that we will process withdrawals in accordance with our withdrawal terms and conditions. For more details, please refer to the BS News.

Thank you for your understanding.

Sincerely,

The Bitstables Team 
    `
  }

};

const NewsDetail = () => {
  const { id } = useParams();
  const news = newsDetails[id];

  if (!news) {
    return <h2>News not found</h2>;
  }

  return (
    <div className="news-detail">
      <h2>{news.title}</h2>
      <p className="news-date"> {news.date}</p>
      {parseInt(id) === 8 ? <div style={{ whiteSpace: "pre-wrap", fontSize: "16px" }} dangerouslySetInnerHTML={{ __html: news.content }} /> : (<pre>{news.content}</pre>)}
      < Link to="/information">← Back to News List</Link>
    </div >
  );
};

export default NewsDetail;