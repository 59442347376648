import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./component/Navbar";
import Sidebar from "./component/Sidebar";
import LandingPage from "./component/LandingPage";
import NewsList from "./component/NewsList";
import NewsDetail from "./component//NewsDetail";
import "./App.css";
function App() {
  return (

    <Router>
      <Navbar />
      <Routes>
        {/* Landing Page (No Sidebar) */}
        <Route path="/" element={<LandingPage />} />

        {/* News List Page (Sidebar + Content) */}
        <Route path="/information" element={
          <div className="layout-container">
            <Sidebar />
            <div className="content">
              <NewsList />
            </div>
          </div>
        } />

        {/* News Detail Page (Sidebar + Content) */}
        <Route path="/news/:id" element={
          <div className="layout-container">
            <Sidebar />
            <div className="content">
              <NewsDetail />
            </div>
          </div>
        } />
      </Routes>
    </Router>
  );
}

export default App;