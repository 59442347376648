import React from "react";
import { Link} from "react-router-dom";
import "../css/Navbar.css";
// import logo from "../assets/bit-stables-logo.png"; // Ensure this is the correct path

const Navbar = () => {
    // const location = useLocation();    // Dynamic breadcrumb navigation
    return (
        <nav className="navbar">
            <div className="nav-left">
                {/* Bit Stables logo as an image */}
                <Link to="/">
                    <img src="/logo.png" alt="Bit Stables" className="navbar-logo" />
                </Link>
                <Link to="/" className="nav-link">Home</Link>
                {/* <Link to="/exchange" className="nav-link">Exchange</Link> */}
                <Link to="/information" className="nav-link">News</Link>
            </div>
            {/* <div className="nav-right">
                <Link to="/login" className="nav-link">Log In</Link>
                <Link to="/signup" className="nav-link">Sign Up</Link>
                <button className="language-btn">English ▼</button>
            </div> */}
            {/* Breadcrumb Section */}
            <div className="breadcrumb">
                <a href="https://lin.ee/DrpJOhW" target="_blank" style={{ color: "white", textDecoration: "none" }} rel="noreferrer">Inquiries</a>
            </div>
        </nav>
    );
};

export default Navbar;