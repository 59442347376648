import React from "react";
import { Link } from "react-router-dom";
import "../css/Sidebar.css";

const Sidebar = () => {
  return (
    <aside className="sidebar">
      <h2>📢 News</h2>
      <nav>
        <ul>
          <li>
            <Link to="/information" className="sidebar-link active">
              <span className="icon">📄</span> Important Notice
            </Link>
          </li>
          {/* <li>
            <Link to="/new-listing" className="sidebar-link">
              <span className="icon">🆕</span> New Listing
            </Link>
          </li>
          <li>
            <Link to="/release" className="sidebar-link">
              <span className="icon">📑</span> Release
            </Link>
          </li> */}
        </ul>
      </nav>
    </aside>
  );
};

export default Sidebar;