import React from "react";
import "../css/LandingPage.css";

const LandingPage = () => {
  return (
    <div className="landing-container">
      <img src="/1.png" alt="1" className="land-img" />
      <img src="/2.png" alt="2" className="land-img" />
      <img src="/3.png" alt="3" className="land-img" />
    </div>
  );
};

export default LandingPage;