import React from "react";
import { Link } from "react-router-dom";
import "../css/NewsList.css";

const newsData = [
  { id: 1, title: "日本語表示の終了について", date: "2021-07-15", preview: "2021年8月14日にて日本語表示が終了致します。 " },
  { id: 2, title: "Notice of Termination of Withdrawal Service for Delisted Currencies and USDT", date: "2020-10-01", preview: "Withdrawal service end date : December 31, 2020" },
  { id: 3, title: "Regarding transaction restrictions for residents of Japan", date: "2019-03-03", preview: "Notice of end of Japanese language support" },
  { id: 4, title: "Notice of withdrawal restrictions", date: "2019-03-03", preview: "Notice of Minimum Withdrawal Limit and Precautions" },
  { id: 5, title: " Notice of Removal of Deposit Restrictions", date: "2019-01-31", preview: "Notice of Removal of Deposit Restrictions" },
  { id: 6, title: " End of Chinese, Korean, Japanese Language Display", date: "2021-07-15", preview: "As of August 14, 2021, the Chinese, Korean, and Japanese language display will be discontinued." },
  { id: 7, title: " Re: Invalidity of Email Addresses that are Violating Request for Comments (RFC)", date: "2020-01-01", preview: "it is impossible to make new registrations with email addresses falling into such cases." },
  { id: 8, title: " Bitstables Service Termination Notice", date: "2024-12-31", preview: "We would like to express our sincere gratitude for your continued support of Bitstables." },
];

const NewsList = () => {
  const sortednewsData = newsData.sort((a, b) => new Date(b.date) - new Date(a.date));

  return (
    <div className="news-list">
      <h2>📢 Important Notice</h2>
      {sortednewsData.map((news) => (
        <div key={news.id} className="news-item">
          <span className="news-date">📅 {news.date}</span>
          <h3><Link to={`/news/${news.id}`}>{news.title}</Link></h3>
          <p>{news.preview} <Link to={`/news/${news.id}`} className="news-details">View the details &gt;</Link></p>
        </div>
      ))}
    </div>
  );
};

export default NewsList;